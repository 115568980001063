import Flex from "@components/elements/flex"
import styled from "styled-components"

export const IndigoCard = styled(Flex)`
  background: ${({ secondary, theme }) =>
    secondary
      ? `color-mix(in srgb, ${theme.color.indigo500} 25%, transparent)`
      : `linear-gradient(to bottom, color-mix(in srgb, ${theme.color.indigo300} 25%, transparent), color-mix(in srgb, ${theme.color.indigo500} 25%, transparent))`};

  color: white;
  border: 1px
    ${({ secondary, theme }) =>
      secondary
        ? `dashed ${theme.color.indigo300}`
        : `solid ${theme.color.indigo300}`};
  border-radius: 1rem;
  box-shadow: ${({ secondary }) =>
    secondary
      ? null
      : `0px 16px 16px rgba(0, 0, 0, 0.16), inset 0px 0px 16px rgba(112, 126, 250, 0.16)`};
  backdrop-filter: blur(3.5px);
  width: 100%;
  height: 100%;

  transition: box-shadow 0.3s ease-in-out;

  &:hover {
    box-shadow: ${({ secondary }) =>
      secondary
        ? `0px 16px 16px rgba(0, 0, 0, 0.16), inset 0px 0px 16px rgba(112, 126, 250, 0.16)`
        : `0px 16px 16px rgba(0, 0, 0, 0.32), inset 0px 0px 16px rgba(112, 126, 250, 0.32)`};
  }
`
