export const HEADER_CONTAINER_SPACING = {
  margin: "10rem auto 8rem",
  marginSm: "6rem auto 2rem",
}

export const CONTAINER_LARGE_SPACING = {
  margin: "12rem auto",
  marginMd: "8rem auto",
  marginSm: "4rem auto",
}

export const CONTAINER_SPACING = {
  margin: "8rem auto",
  marginSm: "4rem auto",
}
