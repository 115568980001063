import { GlowBorderCard } from "@components/card/glow-border"
import { IndigoCard } from "@components/card/indigo"
import { Form } from "@components/common/form"
import { HighGradient, LowGradient } from "@components/common/gradient"
import GradientText from "@components/common/gradient-text"
import { TestimonialUserVertical } from "@components/common/testimonial"
import Anchor from "@components/elements/anchor"
import Button from "@components/elements/button"
import Div from "@components/elements/div"
import Flex from "@components/elements/flex"
import Grid from "@components/elements/grid"
import Heading from "@components/elements/heading"
import Modal from "@components/elements/modal"
import Paragraph from "@components/elements/paragraph"
import Span from "@components/elements/span"
import Image from "@components/image"
import { FormWrapper } from "@components/styles/form-wrapper"
import { useGradient } from "@hooks/use-gradient"
import { processImages } from "@utils/process-images"
import {
  CONTAINER_SPACING,
  HEADER_CONTAINER_SPACING,
} from "@utils/standard-spacing"
import { graphql, useStaticQuery } from "gatsby"
import React, { useCallback, useState } from "react"
import HubspotForm from "react-hubspot-form"
import styled, { keyframes, useTheme } from "styled-components"

const float = keyframes`
	0% {
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
		transform: translatey(0px);
	}
	50% {
  filter: drop-shadow(0 0 15px rgba(0, 0, 0, 0.4));
		transform: translatey(-20px);
	}
	100% {
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
		transform: translatey(0px);
	}
`

const LogoCircle = styled.div`
  width: 100px;
  height: 100px;
  background: white;
  border-radius: 100%;
  filter: drop-shadow(0 0 10px rgba(0, 0, 0, 0.2));
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  animation: ${float} 6s ease-in-out infinite;
  animation-delay: ${({ delay }) => (delay ? delay : 0)}s;
  will-change: transform;
`

export const Partners = () => {
  const { images: imageData } = useStaticQuery(graphql`
    query PartnersPage {
      images: allDirectusFiles(
        filter: {
          directus_id: {
            in: [
              "1b0caa2d-094e-47c5-91fb-364dc2a065ef"
              "6c9f921e-aeb8-4479-ad1f-b07a29f9ac1b"
              "87f4ed9f-5e75-43d5-aa57-29f06877a7e7"
              "43584351-5a43-4062-a59b-261d76a38565"
              "3d7d9b7b-f24a-4526-a9cf-55d849c80d6a"
              "e790791c-30f3-4a60-be5a-6688e7d4a8f2"
              "b143ae2d-fab4-4821-b1a9-c3766fcf9317"
              "6a591b3b-ba1e-49df-aea6-fbfd87e562d1"
              "d81cdb7c-ad6a-4e5f-ac57-9da1ebdd14c0"
              "ea14cb64-be70-480f-92d1-6664cceb7234"
              "294fdc12-f8ab-435e-b99e-501ee68a6ccb"
              "582522df-27cf-4067-ab1c-a4970e73bddc"
              "f12c5ac3-0479-4b9e-8085-d39b74f0d8ab"
              "cf8dbad8-17cb-4a3e-bd5c-6f3672a21088"
              "1ba49946-dcf9-4337-ac5c-3ff3b4bd3c83"
              "54227282-61d2-49b6-ae01-77913e13ae97"
              "85857691-ec43-4f1d-adcb-875ca3185edf"
              "eca6429c-f139-4a7c-b1d0-ff4a66415078"
              "006366d0-62d1-40f5-9043-916a0303c0c9"
              "cca1f9a8-1864-4763-8342-429aca20bac8"
              "d98888ba-539f-4b21-bd2b-5026d4725836"
              "95fb17ea-830e-4f27-9875-ad562f3f0332"
            ]
          }
        }
      ) {
        edges {
          node {
            cdn
            title
            placeholder
            directus_id
          }
        }
      }
    }
  `)

  const images = processImages(imageData.edges)
  const { shadow, color, gradient: themeGradient } = useTheme()
  const { gradient } = useGradient()

  const [contactOpen, setContactOpen] = useState(false)
  const handleContactClick = useCallback(() => {
    setContactOpen(true)
  }, [setContactOpen])

  return (
    <>
      <Modal show={contactOpen} setModalState={setContactOpen}>
        <FormWrapper>
          <Form>
            <HubspotForm
              portalId="7477725"
              formId="344ede1a-eca1-4a2b-b1f0-9b4734bffcb0"
            />
          </Form>
        </FormWrapper>
      </Modal>
      <Div position="relative" overflow="hidden">
        <HighGradient
          src={images["b143ae2d-fab4-4821-b1a9-c3766fcf9317"].cdn}
          loading="eager"
        />
        <Flex gap="6rem" gapSm="4rem" {...HEADER_CONTAINER_SPACING}>
          <Div center position="relative" zIndex={1} textContainer>
            <Flex gap="1rem" margin="0 0 1rem">
              <Flex
                flexFlow="row"
                gap="4rem"
                margin="0 auto"
                justifyContent="center"
              >
                <LogoCircle>
                  <Image
                    file={images["87f4ed9f-5e75-43d5-aa57-29f06877a7e7"]}
                  />
                </LogoCircle>
                <LogoCircle delay={1}>
                  <Image
                    file={images["54227282-61d2-49b6-ae01-77913e13ae97"]}
                  />
                </LogoCircle>
                <LogoCircle delay={0.5}>
                  <Image
                    file={images["cca1f9a8-1864-4763-8342-429aca20bac8"]}
                    style={{ padding: "0.5rem" }}
                  />
                </LogoCircle>
              </Flex>
              <Flex
                flexFlow="row"
                gap="4rem"
                margin="0 auto"
                justifyContent="center"
              >
                <LogoCircle delay={1}>
                  <Image
                    file={images["eca6429c-f139-4a7c-b1d0-ff4a66415078"]}
                    style={{ padding: "0.5rem" }}
                  />
                </LogoCircle>
                <LogoCircle delay={0.5}>
                  <Image
                    file={images["d98888ba-539f-4b21-bd2b-5026d4725836"]}
                    style={{ padding: "0.5rem" }}
                  />
                </LogoCircle>
              </Flex>
            </Flex>
            <Heading center fontSize="4rem" mobileScale={0.6} margin="0 0 1rem">
              Our Partners
            </Heading>
            <Paragraph
              center
              fontSize="1.6rem"
              maxWidth="40ch"
              lineHeight="150%"
              fontWeight={600}
              margin="0 auto 2rem"
            >
              Join our mission to make analytics better for data <b>and</b>{" "}
              business teams.
            </Paragraph>
            <Button
              color={color.indigo600}
              notice={false}
              background={themeGradient.powerMetricsButtonAlt}
              href={
                "https://docs.google.com/forms/d/e/1FAIpQLScxaoyioh4ROGyhsI5pQX3_Xgz5sjymCJSG3dceD9UuHP1s7g/viewform"
              }
            >
              Become a partner
            </Button>
          </Div>
          <Div textContainer>
            <Flex gap="2rem" center>
              <Paragraph
                fontSize="2rem"
                maxWidth="47ch"
                fontWeight={700}
                lineHeight="150%"
              >
                “PowerMetrics allows us to dramatically shorten the time to
                value for our customers. The metrics platform isn’t only
                flexible, it’s super user friendly and accessible to everyone.”
              </Paragraph>
              <TestimonialUserVertical
                name="Therese Moriarty"
                title="Founder and Pricipal"
                company="Eyeful LLC"
                image={images["1b0caa2d-094e-47c5-91fb-364dc2a065ef"]}
              />
            </Flex>
          </Div>
        </Flex>
        <Flex gap="8rem" gapSm="4rem" container {...CONTAINER_SPACING}>
          <Flex gap="3rem">
            <Heading center as="h2">
              Partner{" "}
              <GradientText variant="powermetrics">Program</GradientText>
            </Heading>
            <Flex gap="1rem">
              <Grid columns="repeat(3, 1fr)" columnsMd="1fr" gap="1rem">
                <GlowBorderCard>
                  <Flex gap="1rem" padding="2rem 2rem 4rem">
                    <Flex
                      width="60px"
                      height="60px"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="100%"
                      background="white"
                      boxShadow={shadow.default}
                      margin="0 0 1rem"
                    >
                      <Image
                        width={40}
                        objectFit="scale-down"
                        file={images["f12c5ac3-0479-4b9e-8085-d39b74f0d8ab"]}
                      />
                    </Flex>
                    <Div>
                      <Heading as="h4" margin="0 0 1rem">
                        Grow your business
                      </Heading>
                      <Paragraph fontSize="1.1rem" lineHeight="150%">
                        Collaborate with the PowerMetrics team for co-marketing
                        activities and sales and professional services
                        opportunities.
                      </Paragraph>
                    </Div>
                  </Flex>
                </GlowBorderCard>
                <GlowBorderCard>
                  <Flex gap="1rem" padding="2rem 2rem 4rem">
                    <Flex
                      width="60px"
                      height="60px"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="100%"
                      background="white"
                      boxShadow={shadow.default}
                      margin="0 0 1rem"
                    >
                      <Image
                        file={images["cf8dbad8-17cb-4a3e-bd5c-6f3672a21088"]}
                        width={40}
                      />
                    </Flex>
                    <Div>
                      <Heading as="h4" margin="0 0 1rem">
                        Leverage the community
                      </Heading>
                      <Paragraph fontSize="1.1rem" lineHeight="150%">
                        Connect with other partners and our team. Learn from
                        community experts, share best practices, find solutions
                        and grow your network.
                      </Paragraph>
                    </Div>
                  </Flex>
                </GlowBorderCard>
                <GlowBorderCard>
                  <Flex height="100%" gap="1rem" padding="2rem 2rem 4rem">
                    <Flex
                      width="60px"
                      height="60px"
                      alignItems="center"
                      justifyContent="center"
                      borderRadius="100%"
                      background="white"
                      boxShadow={shadow.default}
                      margin="0 0 1rem"
                    >
                      <Image
                        width={30}
                        objectFit="scale-down"
                        file={images["1ba49946-dcf9-4337-ac5c-3ff3b4bd3c83"]}
                      />
                    </Flex>
                    <Div>
                      <Heading as="h4" margin="0 0 1rem">
                        Accelerate learning
                      </Heading>
                      <Paragraph fontSize="1.1rem" lineHeight="150%">
                        Learn faster with customized training for your team.
                      </Paragraph>
                    </Div>
                  </Flex>
                </GlowBorderCard>
              </Grid>
              <Grid columns="repeat(2, 1fr)" columnsSm="1fr" gap="1rem">
                <GlowBorderCard>
                  <Flex height="100%" gap="1rem" padding="2rem">
                    <Div>
                      <Span>Case Study</Span>
                      <Heading as="h4" margin="0.25rem 0 1rem">
                        The Project Booth
                      </Heading>
                      <Paragraph
                        margin="0 0 1rem"
                        fontSize="1.1rem"
                        lineHeight="150%"
                      >
                        Enabling all businesses with metric-based analytics.
                      </Paragraph>
                      <Anchor
                        margin="auto 0 0"
                        color={color.indigo300}
                        fontWeight={600}
                        arrow
                        link={
                          "https://www.klipfolio.com/blog/case-study-theprojectbooth"
                        }
                      >
                        Learn more
                      </Anchor>
                    </Div>
                  </Flex>
                </GlowBorderCard>
                <GlowBorderCard>
                  <Flex height="100%" gap="1rem" padding="2rem">
                    <Div>
                      <Span>Case Study</Span>
                      <Heading as="h4" margin="0.25rem 0 1rem">
                        Eyeful
                      </Heading>
                      <Paragraph
                        margin="0 0 1rem"
                        fontSize="1.1rem"
                        lineHeight="150%"
                      >
                        Reducing time to value with PowerMetrics.
                      </Paragraph>
                      <Anchor
                        margin="auto 0 0"
                        color={color.indigo300}
                        fontWeight={600}
                        arrow
                        link="https://www.klipfolio.com/blog/partner-case-study-eyeful"
                      >
                        Learn more
                      </Anchor>
                    </Div>
                  </Flex>
                </GlowBorderCard>
              </Grid>
            </Flex>
          </Flex>
        </Flex>
      </Div>
      <Div
        center
        padding="8rem 0"
        paddingSm="4rem 0"
        background={color.indigo700}
        width="100%"
        position="relative"
        overflow="hidden"
      >
        <HighGradient src={gradient.cdn} loading="lazy" />
        <Flex
          position="absolute"
          top="-10%"
          style={{
            maskImage: "linear-gradient(to bottom, black 40%, transparent 70%",
          }}
          width="100%"
          zIndex="0"
          alignItems="center"
        >
          <Image
            file={images["95fb17ea-830e-4f27-9875-ad562f3f0332"]}
            width={1600}
          />
        </Flex>
        <Div position="relative" margin="0 0 6rem" marginSm="0 0 4rem">
          <Heading color={"white"} as="h2" margin="0 0 1.5rem">
            Find a PowerMetrics Partner
          </Heading>
          <Paragraph
            color="white"
            margin="0 auto 2rem"
            lineHeight="150%"
            fontSize="1.4rem"
            maxWidth="60ch"
          >
            Looking for the ideal partner for your PowerMetrics project? We work
            with <b>100+ qualified partners worldwide</b>. Get in touch with us
            and we’ll connect you with a partner that’s right for you.
          </Paragraph>
          <Button
            color={color.indigo600}
            notice={false}
            background={themeGradient.powerMetricsButtonAlt}
            onClick={handleContactClick}
          >
            Get in touch
          </Button>
        </Div>
        <Heading color="white" as="h2" margin="0 0 2rem">
          Featured&nbsp;
          <GradientText variant="powermetrics">Partners</GradientText>
        </Heading>
        <Grid
          columns="repeat(3, 1fr)"
          columnsMd="repeat(2, 1fr)"
          columnsSm="1fr"
          gap="1rem"
          container
        >
          <IndigoCard
            textAlign="left"
            alignItems="flex-start"
            gap="1.5rem"
            padding="2rem"
          >
            <Image
              file={images["6c9f921e-aeb8-4479-ad1f-b07a29f9ac1b"]}
              height={50}
              width={160}
              objectFit="scale-down"
              style={{
                marginTop: "5px",
                marginBottom: "5px",
              }}
            />
            <Paragraph
              color={"white"}
              fontSize="1.1rem"
              margin="0 0 1rem"
              lineHeight="150%"
            >
              Get full visibility into your business performance. We’ll work
              with you to build data stacks, design dashboards, and train your
              staff. Go from guessing to measuring with us.
            </Paragraph>
            <Anchor
              color={color.indigo300}
              link="https://eyeful.us/"
              margin="auto 0 0"
              arrow
            >
              Learn more
            </Anchor>
          </IndigoCard>
          <IndigoCard
            textAlign="left"
            alignItems="flex-start"
            gap="1.5rem"
            padding="2rem"
          >
            <Image
              file={images["54227282-61d2-49b6-ae01-77913e13ae97"]}
              width={60}
              height={60}
              objectFit="scale-down"
            />
            <Paragraph
              color={"white"}
              fontSize="1.1rem"
              margin="0 0 1rem"
              lineHeight="150%"
            >
              A technology consulting firm specializing in development, data,
              and operations integrations. Helping you achieve your vision is
              our mission.
            </Paragraph>
            <Anchor
              color={color.indigo300}
              link="https://morphe.io/"
              margin="auto 0 0"
              arrow
            >
              Learn more
            </Anchor>
          </IndigoCard>
          <IndigoCard
            textAlign="left"
            alignItems="flex-start"
            gap="1.5rem"
            padding="2rem"
          >
            <Image
              file={images["85857691-ec43-4f1d-adcb-875ca3185edf"]}
              height={40}
              width={169}
              objectFit="scale-down"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <Paragraph
              color={"white"}
              fontSize="1.1rem"
              margin="0 0 1rem"
              lineHeight="150%"
            >
              With over 20 years of experience in data and web analysis, i-spark
              creates the custom dashboards you need.
            </Paragraph>
            <Anchor
              color={color.indigo300}
              link="https://coalesced.co/"
              margin="auto 0 0"
              arrow
            >
              Learn more
            </Anchor>
          </IndigoCard>
          <IndigoCard
            textAlign="left"
            alignItems="flex-start"
            gap="1.5rem"
            padding="2rem"
          >
            <Image
              file={images["eca6429c-f139-4a7c-b1d0-ff4a66415078"]}
              height={60}
              width={60}
              objectFit="scale-down"
            />
            <Paragraph
              color={"white"}
              fontSize="1.1rem"
              margin="0 0 1rem"
              lineHeight="150%"
            >
              Turn your numbers into a story with simplified dashboards. Rapidly
              grow your business by knowing exactly what to do and when - all
              without drowning in daily operations.
            </Paragraph>
            <Anchor
              color={color.indigo300}
              link="https://www.theprojectbooth.com/"
              margin="auto 0 0"
              arrow
            >
              Learn more
            </Anchor>
          </IndigoCard>
          <IndigoCard
            textAlign="left"
            alignItems="flex-start"
            gap="1.5rem"
            padding="2rem"
            paddingSm="2rem"
          >
            <Image
              file={images["006366d0-62d1-40f5-9043-916a0303c0c9"]}
              height={40}
              width={234}
              objectFit="scale-down"
              style={{ marginTop: "10px", marginBottom: "10px" }}
            />
            <Paragraph
              color={"white"}
              fontSize="1.1rem"
              margin="0 0 1rem"
              lineHeight="150%"
            >
              Datateer is the easiest way to get custom data visualizations and
              real-time reporting dashboards. As “managed analytics”
              consultants, we integrate technologies including data warehouses,
              ETLs, and business intelligence (BI) layers.
            </Paragraph>
            <Anchor
              color={color.indigo300}
              link="https://www.datateer.com/"
              margin="auto 0 0"
              arrow
            >
              Learn more
            </Anchor>
          </IndigoCard>
          <IndigoCard
            secondary
            center
            alignItems="center"
            justifyContent="center"
            padding="2rem"
          >
            <Div margin="-1rem 0 0" marginSm="0">
              <Heading inherit color="white" as="h2" margin="0 0 1rem">
                Join us
              </Heading>
              <Paragraph
                color={"white"}
                fontSize="1.1rem"
                lineHeight="150%"
                margin="0 0 1rem"
              >
                Become a PowerMetrics partner.
              </Paragraph>
              <Anchor
                color={color.indigo300}
                arrow
                link={
                  "https://docs.google.com/forms/d/e/1FAIpQLScxaoyioh4ROGyhsI5pQX3_Xgz5sjymCJSG3dceD9UuHP1s7g/viewform"
                }
              >
                Get in touch
              </Anchor>
            </Div>
          </IndigoCard>
        </Grid>
      </Div>
      <Div
        position="relative"
        zIndex={1}
        center
        width="100%"
        height="100%"
        overflow="hidden"
      >
        <LowGradient src={gradient.cdn} loading="lazy" />
        <Div padding="8rem 0" paddingSm="4rem 0">
          <Heading margin="0 0 2rem" as="h2">
            Join the mission
          </Heading>
          <Button
            color={color.indigo600}
            notice={false}
            background={themeGradient.powerMetricsButtonAlt}
            href={
              "https://docs.google.com/forms/d/e/1FAIpQLScxaoyioh4ROGyhsI5pQX3_Xgz5sjymCJSG3dceD9UuHP1s7g/viewform"
            }
          >
            Become a partner
          </Button>
        </Div>
      </Div>
    </>
  )
}
