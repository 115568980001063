import React from "react"
import Layout from "@components/layout"
import { Partners } from "@components/pages/partners"

const PartnerProgram = () => {
  return (
    <Layout
      fullWidth
      title={`Partners`}
      description={`Join our mission to make data analytics better for data and business teams alike.`}
      marginless
    >
      <Partners />
    </Layout>
  )
}

export default PartnerProgram
